import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import logo from './isotipo-rgb/logoucpBlanco.png'

import logoFooter from './img/logo2F.png'
import 'font-awesome/css/font-awesome.min.css'






function App() {
  return (
    <div >
      <header className="App-header">
      </header>

      <Navbar bg="li" expand="lg">
        <Container>
          <Navbar.Brand href="https://sistemacuenca.ucp.edu.ar/"><img className="logoucp" src={logo} alt="LogoUCP"></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="https://sistemacuenca.ucp.edu.ar/sistemasweb/">Sistemas Web</Nav.Link>
              <Nav.Link href="https://sistemacuenca.ucp.edu.ar/notasucp/">Sistema Docentes</Nav.Link>
              <Nav.Link href="https://sistemacuenca.ucp.edu.ar/alumnosnotas/">Sistema Alumnos</Nav.Link>
              <Nav.Link href="https://sistemacuenca.ucp.edu.ar/Cobros/">Cobros</Nav.Link>
              <Nav.Link href="https://sistemacuenca.ucp.edu.ar/certificados/">Certificados</Nav.Link>
              <Nav.Link href="https://sistemacuenca.ucp.edu.ar/preinscripcion/">Preinscripción</Nav.Link>
            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>


      <div className="fondo" alt="fondo">
        {/* <img src={logo2} className="logo2" alt="ucplogo"></img> */}
      </div>


<footer>
        <div class="footer">
          <footer class="footer-distributed">
            <div class="footer-left">
              <img src={logoFooter} alt="logo" class="logo-footer" />
              <p class="footer-company-name">UCP &copy; 2021</p>
            </div>

            <div class="footer-center">
              <div>
                <i class="fa fa-map-marker"></i>
                <p>Lavalle 50, Corrientes</p>
              </div>

              <div>
                <i class="fa fa-phone"></i>
                <p>0379 443-6309</p>
              </div>

              <div>
                <i class="fa fa-envelope"></i>
                <p><a href="mailto:contacto@ucp.edu.ar">contacto@ucp.edu.ar
                </a></p>
              </div>
            </div>
            <div class="footer-right">
              <p class="footer-company-about">
                <span>¡Encontranos en nuestras redes!
                </span>
              </p>
              <div class="footer-icons">
                <a href="https://www.facebook.com/cuencadelplata/"><i class="fa fa-facebook"></i></a>
                <a href="https://twitter.com/cuencadelplata"><i class="fa fa-twitter"></i></a>
                <a href="https://www.instagram.com/cuencadelplata"><i class="fa fa-instagram"></i></a>
                <a href="https://youtube.com/user/cuencadelplata"><i class="fa fa-youtube"></i></a>

              </div>
            </div>
          </footer>
        </div>
      </footer>








    </div>





  );
}


export default App;
